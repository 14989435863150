import React from "react"
import CTAButton from "./CTAButton"
import CTABlog from "./CTABlog"

export default function Hero() {
  return(
    <div className="hero--container">
    <div className="hero--content">

      <h1>Sistema de Guararropa & Entradas para Actividades de Ocio</h1>
      <h2>Elimine el <span className="bold">Fraude</span> e <span className="bold">incremente</span> sus ingresos</h2>
      <div className="hero--CTA">
        <CTAButton />
        <CTABlog />
      </div>
      <div className="truster-container">
        <h3 className="truster">
          <span className="material-symbols-outlined">schedule</span>
          +25 años líder del mercado
        </h3>
        <h3 className="truster">
          <span className="material-symbols-outlined">share</span>
          +500 sistemas instalados en discotecas de España y Cataluña
        </h3>
      </div>

    </div>
    </div>

  )
}