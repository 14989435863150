import React from "react"
import CTAButton from "./CTAButton"
import "../styles.css"

export default function Section(props) {
  return(
    <section id={props.id}>
    <div className="section--content">
      <div className="section--text">
        <h2>{props.title}</h2>
        <p>{props.p1}</p>
        <p>{props.p2}</p>
        <p>{props.p3}</p>
      <div className="center-button">
        <CTAButton />
      </div>
      </div>
      <img src={props.img} alt="Imagen maquina" />
    </div>
    </section>
  )
}