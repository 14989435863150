import React from "react";
import "../styles.css";

export default function Form() {
  const apiMail = process.env.REACT_APP_MAIL_CODE;

  return (
    <section id="form">
      <div className="form--content">
        <div className="form--contact-us">
          <h3>Contáctanos</h3>
          <p>
            Te enviaremos una máquina <span className="bold">gratis</span>{" "}
            durante un fin de semana
          </p>
          <p>¡Tú decides si te gusta el producto!</p>
          <br />
          <p>Envíanos un mensaje por</p>
          <p>
            <a className="hover" href="tel:+34 609532559">
              <span className="bold">WhatsApp:</span> +34 609 53 25 59{" "}
            </a>
          </p>
          <p>
            <a className="hover" href="mailto:quotes@qrseven.com">
              <span className="bold">Email:</span> price @ qrseven.com
            </a>
          </p>
        </div>

        <p className="center-text">
          Envia un mensaje con tu <b>nombre</b>, nombre del <b>negocio</b> y el{" "}
          <b>producto</b> en el que estás interesado (<b>Guardarropa</b>,{" "}
          <b>Entradas</b> o los <b>dos</b>)
        </p>

        <p className="center-text bold">
          Te responderémos en menos de 24 horas
        </p>
      </div>
    </section>
  );
}
