import React from "react"
import "../styles.css"

export default function Footer() {
  return(
    <footer>
    <div className="footer--content">
        <h2>QR Seven & Q-Robe</h2>
        <p>Desde 1997</p>

      <div className="footer--info">
        <div className="footer--address">
          <h5>Address</h5>
          <p>C/ Jesus Santos Rein 13</p>
          <p>29640</p>
          <p>Fuengirola, Málaga</p>
        </div>
        <div className="footer--contact">
          <h5>Contacts</h5>
          <p><a href="mailto:quotes@qrseven.com">quotes@qrseven.com</a></p>
          <p><a href="tel:+34 609532559">Tlf: +34 609 53 25 59 </a></p>
        </div>
      </div>
    </div>
    </footer>
  )
}