import React from "react"
import CTAButton from "./CTAButton"
import logo from "../images/logo-color.png"
import "../styles.css"

export default function Navbar() {
  return(
    <div className="navbar--container">
    <div className="navbar--content">
      <a href="#"><img className="logo" src={logo} alt="QR Seven Logo" /></a>
      <nav>
        <ul className="navbar--list">
          <li className="list-item"><a href="#guardarropa">Guardarropa</a></li>
          <li className="list-item"><a href="#entradas">Entradas</a></li>
          <li className="navbar--blo  g-button" ><a href="/blog.html">Blog</a></li>
          <CTAButton />
        </ul>
      </nav>
    </div>
    </div>
  )
}