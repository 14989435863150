import React from "react"
import Navbar from "./components/Navbar"
import Hero from "./components/Hero"
import Section from "./components/Section"
import Footer from "./components/Footer"
import Form from "./components/Form"
import guardarropa from './images/guardarropa.webp';
import entradas from './images/entradas.webp';

export default function App() {
  return (
    <div className="app--container">
      <Navbar />
      <Hero />
      <div className="app--guadarropa">
        <Section 
          id="guardarropa"
          title="Sistema Guadarropa"
          img={guardarropa}
          p1="En nuestro compromiso por ofrecer soluciones completas en el ámbito del guardarropa para discotecas y ocio, hemos diseñado un sistema de gestión de guardarropía que garantiza la eficacia y seguridad en la administración de tus servicios."
          p2= "Nuestro sistema de guardarropa para ocio es altamente personalizable. Ofrecemos una variedad de colores para los tickets que se adaptan a la estética y el estilo de tu establecimiento, permitiéndote brindar una experiencia aún más especial a tus clientes. "
          p3="¡Contáctanos hoy mismo para obtener más información!"
        />
      </div>
      <div className="app--entradas">
        <Section 
          id="entradas"
          title="Sistema Entradas"
          img={entradas}
          p1="En nuestra búsqueda constante por brindar soluciones integrales en el ámbito del ocio nocturno, hemos desarrollado un sistema de control de entradas para discotecas que garantiza la eficacia y seguridad en la gestión de tu evento. "
          p2="Nuestro sistema de entradas es versátil y se adapta a tus necesidades. Los tickets de entradas están disponibles en múltiples colores y pueden tener un tamaño de 100mm o de 70mm, ofreciendo flexibilidad en el diseño y la impresión. Esto te permite personalizar aún más la experiencia de tus clientes y optimizar la operación de tu evento. "
          p3="¡Contáctanos hoy mismo!"
        />  
      </div>
      <Form />

      <Footer />
    </div>
  )
}

